import React from "react";
import { Container, Hidden, Box } from "@material-ui/core";
import "../css/custom.css";
import Trending from "../components/Trending/Trending";
import Details from "./Details/Details";
import Compare from "./Compare/Compare";
import PhoneInfo from "./PhoneInfo/PhoneInfo";
import { createCdnURL } from "../config/app";
import { appURL } from "../config/app";
import Helmet from "react-helmet";

const dpt = (group) => {
  let g = Object.assign({}, group);
  let n = g;
  if (n.parent) {
    n = dpt(n.parent);
    delete g["parent"];
    n.child = g;
  }

  return n;
};

const normalizeT = (node) => {
  if (node.valuesMap && Object.keys(node.valuesMap).length > 0) {
    let m = {};
    for (let k in node.valuesMap) {
      let vals = node.valuesMap[k];
      if (!Array.isArray(node.valuesMap[k])) {
        vals = [node.valuesMap[k]];
      }
      if (!m[k]) {
        let t = Object.assign({}, vals[0]);
        delete t["valuesMap"];
        m[k] = t;
      }

      for (let j in vals) {
        if (!vals[j].valuesMap) {
          continue;
        }
        let mm = Object.values(vals[j].valuesMap);
        m[k].value = m[k].value.concat(mm);
      }
    }
    node.value = Object.values(m);
    return node;
  } else {
    return node;
  }
};

const flattenObject = (obj) => {
  if (!obj || typeof obj === "string" || Array.isArray(obj)) {
    return [{ k: "", v: obj }];
  }

  let keys = [];
  for (let i in obj) {
    if (!i || !obj[i] || typeof obj[i] === "undefined") {
      continue;
    }

    let r = flattenObject(obj[i]);
    for (let k in r) {
      let l = [i, r[k].k]
        .filter((v) => v !== "" && typeof v != "undefined")
        .join(".");
      keys.push({
        k: l,
        v: r[k].v,
      });
    }
  }

  return keys;
};

const sortByOrder = (arr) => {
  return arr.sort(function (a, b) {
    return parseInt(a.order) - parseInt(b.order);
  });
};

const flattenValue = (format, value) => {
  if (typeof value === "string") {
    if (format && format !== "" && value.length > 0) {
      return format.replace("{{__VALUE__}}", value);
    }
    return value;
  }

  if (Array.isArray(value)) {
    value = sortByOrder(value);

    let a = [];
    for (let i in value) {
      if (value[i].key) {
        a.push(flattenValue(value[i].format, value[i].value));
      }
    }

    if (a.length > 0) {
      return flattenValue(format, a.filter((v) => v !== "").join(""));
    }

    a = [];
    for (let i in value) {
      if (!value[i].key) {
        a.push(flattenValue(format, value[i]));
      }
    }
    if (a.length > 0) {
      return flattenValue(format, a.filter((v) => v !== "").join(" / "));
    }
  }

  return value;
};

const buildAttributes = (fields, attributes) => {
  let new_attributes = {};
  let temp = flattenObject(attributes);
  for (let i in temp) {
    new_attributes[temp[i].k] = temp[i].v;
  }

  attributes = new_attributes;

  let fieldsMap = [];
  // build field map
  for (let kk in fields) {
    let field = fields[kk];

    if (field.group) {
      let g = dpt(field.group);

      let root = {
        name: g.name,
        order: g.order || 1,
        key: g.key,
        format: g.format,
        value: [],
        valuesMap: {},
        prefix: field.section,
        section: field.section,
        scopes: field.scopes,
      };

      if (!fieldsMap[root.key]) {
        fieldsMap[root.key] = root;
      } else {
        root = fieldsMap[root.key];
      }

      let prefix = [field.section, g.key];
      g = g.child;
      let t = [root];
      let i = 0;
      while (g) {
        prefix.push(g.key);
        let k = {
          name: g.name,
          order: g.order || 1,
          key: g.key,
          format: g.format,
          value: [],
          valuesMap: {},
          prefix: prefix.join("."),
        };

        if (!t[i].valuesMap[k.key]) {
          t[i].valuesMap[k.key] = [];
        }

        t[i].valuesMap[k.key].push(k);
        i = i + 1;
        t[i] = k;
        g = g.child || null;
      }

      let el = {
        order: field.order || 1,
        key: field.key,
        format: field.format,
        value: [],
        prefix: prefix.join("."),
        scopes: field.scopes,
      };

      if (attributes[`${el.prefix}.${el.key}`]) {
        el.value = attributes[`${el.prefix}.${el.key}`];
      }

      if (!t[i].valuesMap[el.key]) {
        t[i].valuesMap[el.key] = {};
      }

      t[i].valuesMap[el.key] = el;
    } else {
      let el = {
        name: field.name,
        order: field.order || 1,
        key: field.key,
        format: field.format,
        value: [],
        prefix: field.section,
        section: field.section,
        scopes: field.scopes,
      };

      if (attributes[`${el.prefix}.${el.key}`]) {
        el.value = attributes[`${el.prefix}.${el.key}`];
      }

      fieldsMap[field.key] = el;
    }
  }

  for (let k in fieldsMap) {
    fieldsMap[k] = normalizeT(fieldsMap[k]);
  }

  fields = Object.values(fieldsMap);
  let sections = {};
  for (let i in fields) {
    let field = fields[i];

    if (!sections[field.section]) {
      sections[field.section] = [];
    }

    let new_field = {};

    let v = flattenValue(field.format, field.value);
    if (v === "") {
      continue;
    }

    new_field = {
      name: field.name,
      value: v,
      order: field.order,
    };

    if (field.scopes && field.scopes.indexOf("pdv") > -1) {
      sections[field.section].push(new_field);
    }
  }

  return sections;
};

const buildKeyFeatures = (fields, attributes) => {
  let groups = {};
  let section = Object.values(fields).filter(
    (f) => f.section === "Key Features" && f.scopes.indexOf("key_features") > -1
  );

  for (let i in section) {
    let field = section[i];
    if (field.group) {
      if (!groups[field.group.key]) {
        groups[field.group.key] = [];
      }

      let value = attributes["Key Features"][field.group.key][field.key] || "";
      if (
        field.format &&
        field.format !== "" &&
        typeof field.format !== "undefined"
      ) {
        value = field.format.replace("{{__VALUE__}}", value);
      }

      groups[field.group.key].push({ key: field.key, name: field.name, value });
    }
  }

  return groups;
};

const buildComparisons = (fields, attributes) => {
  let groups = {};
  let section = Object.values(fields).filter(
    (f) => f.section === "Key Features" && f.scopes.indexOf("comparisons") > -1
  );
  for (let i in section) {
    let field = section[i];
    if (field.group) {
      if (!groups[field.group.key]) {
        groups[field.group.key] = [];
      }

      let value = attributes["Key Features"][field.group.key][field.key] || "";
      if (
        field.format &&
        field.format !== "" &&
        typeof field.format !== "undefined"
      ) {
        value = field.format.replace("{{__VALUE__}}", value);
      }

      groups[field.group.key].push({
        name: field.group.name,
        value,
        order: field.group.order,
      });
    }
  }

  let data = [];
  for (let i in groups) {
    let fields = groups[i];
    let val = [];
    sortByOrder(fields).forEach((o, k) => {
      if (Array.isArray(o.value)) {
        val.push(o.value.join(" / "));
      } else {
        val.push(o.value);
      }
    });

    data.push({
      name: fields[0].name,
      value: val.join(" "),
      order: fields[0].order,
    });
  }

  return data;
};


const removeTags = (str) => { 
  if ((str===null) || (str==='')) 
      return false; 
  else
      str = str.toString(); 
        
  // Regular expression to identify HTML tags in  
  // the input string. Replacing the identified  
  // HTML tag with a null string. 
  return str.replace( /(<([^>]+)>)/ig, ''); 
} 

const getSummary = (title, html) => {
  let base = `Latest price and specifications of ${title} mobile phone in Pakistan, Daily updates on local markets and online prices in Lahore, Peshawar, Islamabad , whatmobile, Daraz.pk, Homeshopping.pk, Telemart.pk, Priceoye.com.pk `;
  const desc = removeTags(html)

  if (desc) {
    return (
      base +
      ", " +
      desc
        .substring(0, 100)
        .trim()
        .replace(/\r?\n|\r/g, "")
        .replace('"', '\\"')
        .replace("{", "")
        .replace("}", "")
    );
  }

  return base;
};

const buildAggregateOffers = (offers) => {
  let prices = [];
  let _offers = [];
  offers.forEach((offer) => {
    prices.push(parseFloat(offer.price));
    _offers.push({
      "@type": "Offer",
      url: offer.external_url,
      priceCurrency: "PKR",
      Price: offer.price,
      availability: "http://schema.org/InStock",
      seller: {
        "@type": "Organization",
        name: offer.shop_name,
        logo: offer.shop_logo || "",
      },
    });
  });

  let highPrice = prices.sort().reverse().pop();
  let lowestPrice = prices.sort().pop();
  let r = JSON.parse(`{
    "offers": {
    "@type": "AggregateOffer",
    "highPrice": "${highPrice}",
    "lowPrice": "${lowestPrice || 0}",
    "offerCount": "${_offers.length}",
    "priceCurrency": "PKR",
    "offers": ${JSON.stringify(_offers)}
    }
  }`);

  return r;
};

const getJSONLD = ({
  id,
  public_id,
  images,
  cover_image,
  category,
  title,
  description,
  parsedAttributes,
  brand,
  url,
  price,
  shop_links,
}) => {
  category = JSON.parse(category);
  console.log(`{
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "name": "Product Navigation",
    "itemListElement": [{
      "@type": "ListItem",
      "position": 1,
      "item":{
        "@id": "${appURL}/",
        "name": "Home - Latest Price updates & news"
      }
    },{
      "@type": "ListItem",
      "position": 2,
      "item":{
        "@id": "${appURL}/search",
        "name": "${(category.name || "")}"
      }
    },{
      "@type": "ListItem",
      "position": 3,
      "item":{
        "@id": "${appURL}/${url}",
        "name":  "${title.trim()} Price & Specifications in Pakistan"
      }
    }]
  }`)

  let b = JSON.parse(`{
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "name": "Product Navigation",
    "itemListElement": [{
      "@type": "ListItem",
      "position": 1,
      "item":{
        "@id": "${appURL}/",
        "name": "Home - Latest Price updates & news"
      }
    },{
      "@type": "ListItem",
      "position": 2,
      "item":{
        "@id": "${appURL}/search",
        "name": "${(category.name || "")}"
      }
    },{
      "@type": "ListItem",
      "position": 3,
      "item":{
        "@id": "${appURL}/${url}",
        "name":  "${title.trim()} Price & Specifications in Pakistan"
      }
    }]
  }`);

  let model = parsedAttributes["Summary"].filter(
    (o) => o && o.name.toLowerCase() === "model"
  );
  model = model.length > 0 ? model[0].value : "";

  let color =
    parsedAttributes["Summary"].filter(
      (o) => o.name.toLowerCase() === "color"
    )[0].value || "";
  color = color.length > 0 ? color[0].value : "";

  let sku = `PI-${public_id.split("-").pop().toUpperCase()}`;

  let _shop_links = shop_links.concat([]) || [];
  _shop_links.push({
    price,
    shop_name: brand,
    external_url: `${appURL}/${url}`,
  });
  let offers = buildAggregateOffers(_shop_links);

  let pp = `{
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": "${title}",
    "url": "${appURL}/${url}",
    "image": "${cover_image}",
    "description": "${getSummary(title, description + "")}",
    "brand": {
      "@type": "Brand",
      "name": "${brand}"
    },
    "offers": ${JSON.stringify(offers["offers"])},
    "sku": "${sku}",
    "mpn": "${title}",
    "category": "${category.name}",
    "model": "${title} - ${model}",
    "color": "${color || ""}",
    "additionalProperty": []
  }`;

  pp = JSON.parse(pp);
  for (let i in parsedAttributes) {
    if (i === "Featured Keys") {
      continue;
    }
    for (let k in parsedAttributes[i]) {
      pp["additionalProperty"].push({
        "@type": `PropertyValue`,
        name: `${i} - ${parsedAttributes[i][k].name}`,
        value: `${parsedAttributes[i][k].value}`,
      });
    }
  }

  return [b, pp];
};

function Product(props) {
  const { product } = props;
  let comparisons = product.comparisons;

  let attributes =
    typeof product.attributes === "string"
      ? JSON.parse(product.attributes)
      : product.attributes;
  let rawAttributes = Object.assign({}, attributes);
  attributes = buildAttributes(product.fields, attributes);
  product.brand = rawAttributes["Summary"]["brand"] || "";
  product.release_status = rawAttributes["Summary"]["status"] || "";
  product.title = product.title.trim().replace("\t", "")

  product.key_features = buildKeyFeatures(product.fields, rawAttributes);
  product.parsedAttributes = attributes;

  if (!product.thumbnails) {
    product.thumbnails = JSON.parse(product.images).map((im) =>
      createCdnURL("thumbnail", im)
    );
  }

  if (!Array.isArray(product.images)) {
    product.images = JSON.parse(product.images).map((im) =>
      createCdnURL("large", im)
    );
  }

  if (comparisons.products) {
    comparisons.products.map((p) => {
      p.comparisons = buildComparisons(product.fields, rawAttributes);
      if (p.cover_image.indexOf("medium") < 0) {
        p.cover_image = createCdnURL("medium", p.cover_image);
      }

      return p;
    });
  }

  let jsonLD = getJSONLD(product);

  return (
    <React.Fragment>
      <Helmet>
        <title>{product.title} Price & Specs in Pakistan</title>
        <meta
          name="description"
          content={getSummary(product.title, product.description)}
        />

        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@electronikspk" />
        <meta name="twitter:title" content={product.title} />
        <meta
          name="twitter:description"
          content={getSummary(product.title, product.description)}
        />
        <meta name="twitter:image" content={product.cover_image} />

        <meta property="og:title" content={product.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${appURL}/${product.url}`} />
        <meta property="og:image" content={product.cover_image} />
        <meta
          property="og:description"
          content={getSummary(product.title, product.description)}
        />
        <meta property="og:site_name" content="electroniks.pk" />
        <meta property="fb:admins" content="Facebook numeric ID" />

        <script type="application/ld+json">{JSON.stringify(jsonLD)}</script>
      </Helmet>
      <Box bgcolor="#ecf0f2" height="100%">
        <Container maxWidth={false}>
          <Hidden implementation="css" mdDown>
            <Trending trendings={product.trendings} />
          </Hidden>
          <Details product={product} />
          <Compare product={product} comparisons={comparisons} />
          <PhoneInfo product={product} />
        </Container>
      </Box>
    </React.Fragment>
  );
}

export default Product;
