import React, { useState, useRef } from "react";
import { Hidden, Box, Grid } from "@material-ui/core";
import classes from "./Imageblock.module.scss";
import "./slider.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";
import LazyImage from "../../../components/LazyImage";
import { Helmet } from "react-helmet";

function Desktop(props) {
  const { thumbnails, items } = props;
  const [current, SetCurrent] = useState(0);
  let sliderRef = useRef(null);

  const handleSlideGoto = (sliderIndex) => {
    sliderRef.slideToIndex(sliderIndex);
    SetCurrent(sliderIndex);
  };
  return (
    <Grid container spacing={1} direction="row" justify="flex-start">
      {/* Inner slider contaienr */}
      <Grid
        container
        lg={2}
        md={2}
        item
        direction="column"
        spacing={1}
        alignItems="center"
      >
        {thumbnails.map((o, i) => (
          <Grid item key={i}>
            <Box
              onClick={() => handleSlideGoto(i)}
              borderRadius={3}
              borderColor={current === i ? "#2f86c2" : "#ddd"}
              boxShadow={current === i ? 2 : 0}
              bgcolor="background.paper"
              border={1}
              className={classes.thumbs}
              style={{ height: "56px", width: "56px" }}
            >
              <img alt={o.name} src={o.url} loading="lazy" />
            </Box>
          </Grid>
        ))}
      </Grid>
      <Grid item lg={10} md={10} xs={12}>
        <Box
          style={{
            minHeight: "350px",
            maxHeight: "500px",
            maxWidth: "400px",
            width: "100%",
            height: "100%",
          }}
        >
          <ImageGallery
            ref={(slider) => (sliderRef = slider)}
            items={items.map((i, k) => ({
              original: i.url,
              originalAlt: i.name,
              originalTitle: i.name,
              index: k,
            }))}
            renderItem={(item) => {
              const itemSrc = item.original;
              if (item.index === 0) {
                return (
                  <LazyImage
                    src={itemSrc}
                    alt={item.originalAlt}
                    className="image-gallery-image"
                  />
                );
              }
              return (
                <LazyImage
                  src={itemSrc}
                  alt={item.originalAlt}
                  className="image-gallery-image"
                  title={item.originalTitle}
                />
              );
            }}
            additionalClass={classes.hf}
            lazyLoad={true}
            showNav={false}
            autoPlay={false}
            showThumbnails={false}
            showPlayButton={false}
            showFullscreenButton={false}
            onSlide={(i) => {
              SetCurrent(i);
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

function Mobile(props) {
  const { thumbnails, items } = props;
  const [current, SetCurrent] = useState(0);
  let sliderRef = useRef(null);
  const [loadable, setLoadable] = useState([]);

  const handleSlideGoto = (sliderIndex) => {
    sliderRef.slideToIndex(sliderIndex);
    if (loadable.indexOf(sliderIndex) < 0) {
      setLoadable((l) => {
        l.push(sliderIndex);
        return l;
      });
    }

    SetCurrent(sliderIndex);
  };

  return (
    <Grid container spacing={1} direction="row" justify="flex-start">
      <Grid item lg={10} md={10} xs={12}>
        <Box
          style={{
            minHeight: "400px",
            width: "100%",
            height: "100%",
          }}
        >
          <ImageGallery
            ref={(slider) => (sliderRef = slider)}
            items={items.map((i, k) => ({
              original: i.url,
              originalAlt: i.name,
              originalTitle: i.name,
              index: k,
            }))}
            renderItem={(item) => {
              const itemSrc = item.original;
              if (item.index === 0) {
                return (
                  <LazyImage
                    key={item.index}
                    src={itemSrc}
                    alt={item.originalAlt}
                    progressive={true}
                    thumbnail={thumbnails[item.index].url}
                    className="image-gallery-image"
                    title={item.originalTitle}
                  />
                );
              }
              return (
                <LazyImage
                  load={loadable.indexOf(item.index) > -1}
                  key={item.index}
                  src={itemSrc}
                  alt={item.originalAlt}
                  className="image-gallery-image"
                  title={item.originalTitle}
                />
              );
            }}
            lazyLoad={false}
            showNav={false}
            autoPlay={false}
            showThumbnails={false}
            showPlayButton={false}
            showFullscreenButton={false}
            onSlide={(i) => {
              SetCurrent(i);
            }}
          />
        </Box>
      </Grid>
      <Grid
        container
        lg={2}
        md={2}
        item
        direction="row"
        spacing={1}
        alignItems="center"
        justify="center"
      >
        {thumbnails.map((o, i) => (
          <Grid item key={i}>
            <Box
              onClick={() => handleSlideGoto(i)}
              borderRadius={3}
              borderColor={current === i ? "#2f86c2" : "#ddd"}
              boxShadow={current === i ? 2 : 0}
              bgcolor="background.paper"
              border={1}
              style={{
                textAlign: "center",
                width: "56px",
                height: "56px",
                padding: "2px",
                marginBottom: "11px",
                maxHeight: "500px",
              }}
            >
              <img alt={o.name} src={o.url} loading="lazy" />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

const Imageblock = (props) => {
  return (
    <Grid item lg={6} md={6} xs={12}>
      {/* Inner slider contaienr */}
      <Hidden implementation="css" only={["sm", "xs"]}>
        <Desktop {...props} />
      </Hidden>

      <Hidden implementation="css" only={["lg", "md"]}>
        <Helmet>
          <link rel="preload" as="image" href={props.items.length > 0 ? props.items[0].url : ""}/>
        </Helmet>
        <Mobile {...props} />
      </Hidden>
    </Grid>
  );
};

export default Imageblock;
