import React from "react";
import { Box, Breadcrumbs, Typography, Link } from "@material-ui/core";
import { NavigateNext as NavigateNextIcon } from "@material-ui/icons";
import classes from "./Breadcrumb.module.scss";

const Breadcrumb = (props) => {
  const { title } = props;
  return (
    <Box className={classes.breadcrumb}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" href={"/"}>
          Home
        </Link>
        <Typography color="inherit">
          {title}
        </Typography>
      </Breadcrumbs>
    </Box>
  );
};

export default Breadcrumb;
