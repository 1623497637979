import React from "react";
import { Box, Link, Typography } from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { currencyFormat } from "../../../config/app";
import styles from "./Pricer.module.scss";
import clsx from "clsx";
import LazyImage from "../../../components/LazyImage";

const ShopPriceList = ({ links }) => {
  if (links.length < 1) {
    return <React.Fragment></React.Fragment>;
  }
  const length = links.length;
  return (
    <Box className={styles.pricerBox}>
      <Typography
        variant="subtitle1"
        style={{
          textTransform: "uppercase",
        }}
      >
        <ShoppingCartIcon fontSize="small"></ShoppingCartIcon> Shop Online
      </Typography>
      {links.map((link, i) => (
        <Box
          key={i}
          className={clsx({ [styles.pricerBrand]: i !== length - 1 })}
        >
          <LazyImage alt={link.shop_name} src={link.shop_logo} />
          <Box className={styles.priceBox}>
            <span>{currencyFormat(link.price)}</span>
            <Link
              href={link.external_url}
              className={styles.priceButton}
              target="_blank"
            >
              Go to Store &gt;
            </Link>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ShopPriceList;
