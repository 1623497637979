import React from "react";
import { Paper, Grid } from "@material-ui/core";
import classes from "./Details.module.scss";
import Breadcrumb from "./Breadcrumbs";
import Imageblock from "./Imageblock/Imageblock";
import MainInfo from "./MainInfo/MainInfo";

const Details = (props) => {
  const { product } = props;

  const breadcrumbs = [
    {
      label: "Home",
      url: "/",
    },
    {
      label: product.title,
      url: product.url,
    },
  ];

  const images = product.images.map((i) => ({ url: i, name: product.title }));
  const thumbnails = product.thumbnails.map((i) => ({
    url: i,
    name: product.title,
  }));

  return (
    <Paper
      elevation={0}
      variant="outlined"
      square
      className={classes.mainPaper}
    >
      <Breadcrumb items={breadcrumbs} title={product.title} url={product.url} />
      <Grid direction="row" container spacing={1}>
        <Imageblock items={images} thumbnails={thumbnails} />
        <MainInfo product={product} />
      </Grid>
    </Paper>
  );
};

export default Details;
