import React from "react";
import { Grid, Box, Typography, Link } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import {
  WidgetsOutlined as WidgetsOutlinedIcon,
  MemoryOutlined as MemoryOutlinedIcon,
  SmartphoneOutlined as SmartphoneOutlinedIcon,
  BatteryChargingFullOutlined as BatteryChargingFullOutlinedIcon,
  StorageOutlined as StorageOutlinedIcon,
  CameraAltOutlined as CameraAltOutlinedIcon,
} from "@material-ui/icons";
import AdbOutlinedIcon from "./os.svg";
import classes from "./MainInfo.module.scss";
import styles from "../../../css/main.module.scss";
import { currencyFormat } from "../../../config/app";
import ShopPriceList from "../ShopPriceList";

function renderStatusOrPrice(product) {
  if (product.release_status === "Coming Soon") {
    return (
      <React.Fragment>
        <span className={classes.comingSoon}>COMING SOON</span>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <span className={classes.arrived}>{currencyFormat(product.price)}</span>
    </React.Fragment>
  );
}

function RenderIcon(props) {
  const { icon } = props;
  switch (icon) {
    case "memory":
      return <MemoryOutlinedIcon></MemoryOutlinedIcon>;
    case "battery_charging_full":
      return (
        <BatteryChargingFullOutlinedIcon></BatteryChargingFullOutlinedIcon>
      );
    case "camera_alt":
      return <CameraAltOutlinedIcon></CameraAltOutlinedIcon>;
    case "smartphone":
      return <SmartphoneOutlinedIcon></SmartphoneOutlinedIcon>;
    case "adb":
      return (
        <img
          loading="lazy"
          alt="Operating System Icon"
          src={AdbOutlinedIcon}
          style={{ width: "24px" }}
        />
      );
    case "sd_storage":
      return <StorageOutlinedIcon></StorageOutlinedIcon>;

    default:
      return <MemoryOutlinedIcon></MemoryOutlinedIcon>;
  }
}

const sortByOrder = (arr) => {
  return arr.sort(function (a, b) {
    return parseInt(a.order) - parseInt(b.order);
  });
};

const MainInfo = (props) => {
  const { product } = props;
  let keyFeatures = [];
  sortByOrder(Object.values(product.key_features)).map((feature) => {
    let icon = feature.filter((v) => v.key.indexOf("icon") > -1).pop();
    let primary = feature.filter((v) => v.key.indexOf("primary") > -1).pop();
    let secondary = feature
      .filter((v) => v.key.indexOf("secondary") > -1)
      .pop();

    keyFeatures.push({
      icon: icon.value,
      primary: Array.isArray(primary.value)
        ? primary.value.join(" / ")
        : primary.value,
      secondary: secondary.value,
    });

    return null;
  });

  return (
    <Grid
      container
      item
      lg={6}
      md={6}
      xs={12}
      className="PARENT"
      alignItems="flex-start"
      direction="column"
      spacing={2}
      style={{ margin: "0" }}
    >
      <Grid item>
        <Typography
          variant="h4"
          component="h1"
          style={{ textTransform: "uppercase" }}
        >
          {product.title}
        </Typography>
        <Box component="span" className="ProdName">
          <Typography
            className={styles.displayiBlock}
            variant="body2"
            gutterBottom
          >
            By&nbsp;
          </Typography>

          <Link
            href={"/" + product.brand.toLowerCase() + "-mobile-phones/"}
            className={[styles.displayiBlock, styles.underlined].join(" ")}
          >
            <Typography variant="body1">{product.brand || "N/A"}</Typography>
          </Link>
        </Box>
        <Box>
          <Rating
            className={styles.vam}
            value={product.ratings}
            readOnly
          ></Rating>
        </Box>
        <Box>
          <Typography color="primary" variant="h5">
            {renderStatusOrPrice(product)}
          </Typography>
        </Box>
      </Grid>

      <Grid item className={classes.Boxy}>
        <Box className={classes.keyFeatures}>
          <Typography variant="subtitle1">
            <WidgetsOutlinedIcon fontSize="small"></WidgetsOutlinedIcon> Key
            Features
          </Typography>
        </Box>
        <Grid
          container
          item
          direction="row"
          spacing={0}
          className={classes.keyBoxes}
        >
          {keyFeatures.map((feature, i) => (
            <Grid item xs={6} lg={4} md={6} key={i}>
              <Box bgcolor="#ecf0f2" borderColor="white" border={1}>
                <Grid
                  container
                  direction="column"
                  spacing={1}
                  alignItems="center"
                >
                  <Grid item>
                    <Box component="span">
                      <RenderIcon icon={feature.icon} />
                    </Box>
                  </Grid>
                  <Grid item style={{ width: "100%" }}>
                    <Typography
                      variant="h6"
                      style={{
                        textAlign: "center",
                        height: "32px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "80%",
                        maxWidth: "100%",
                        margin: "0 auto",
                      }}
                    >
                      {feature.primary}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ textAlign: "center" }}
                    >
                      {feature.secondary}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ))}
          <ShopPriceList links={product.shop_links || []} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MainInfo;
