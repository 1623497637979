import React from "react";
import { Link } from "gatsby";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Paper,
} from "@material-ui/core";
import styles from "./Compare.module.scss";
import styles2 from "../../css/main.module.scss";
import LazyLoad from "react-lazyload";

const Compare = (props) => {
  const { comparisons, product } = props;
  if (!comparisons.public_id) {
    return <React.Fragment></React.Fragment>;
  }

  const headers = comparisons.products.map(({ title, cover_image, url }) => ({
    title,
    cover_image,
    url,
  }));

  let rows = {};
  comparisons.products.forEach((o) => {
    o.comparisons.forEach((a) => {
      if (!rows[a.name]) {
        rows[a.name] = [a.name];
      }

      rows[a.name].push(a.value);
    });
  });

  return (
    <Paper
      elevation={0}
      variant="outlined"
      square
      className={styles2.mainPaper}
    >
      <Typography
        variant="h4"
        style={{
          fontSize: "24px",
          textTransform: "uppercase",
          textAlign: "center",
        }}
      >
        Top Competition for {product.title}
      </Typography>
      <Box style={{ overflowX: "scroll" }}>
        <Box style={{ minWidth: "1024px", textAlign: "center" }}>
          <Grid container direction="column">
            <Grid item>
              <TableContainer component="div">
                <Table
                  size="medium"
                  aria-label="a dense table"
                  className={styles.tableData}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      {headers.map((o, i) => (
                        <TableCell align="center" key={i}>
                          <Box>
                            <LazyLoad height={260} once>
                              <Link to={`/${o.url}`}>
                                <img
                                  loading="lazy"
                                  alt={o.title}
                                  src={o.cover_image + "?v=2"}
                                  height="260"
                                  maxwidth="300"
                                />
                              </Link>
                            </LazyLoad>
                            <br />
                            <Link
                              to={`/${o.url}`}
                              style={{
                                textDecoration: "none",
                                color: "#0a182d",
                              }}
                            >
                              {o.title}
                            </Link>
                          </Box>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {Object.values(rows).map((row, i) => (
                      <TableRow key={i}>
                        {row.map((r, k) => (
                          <TableCell key={k} align="center" scope="row">
                            {r}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
};

export default Compare;
