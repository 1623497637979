import React from "react";
import ProductPage from "../product";
import Layout from "../components/Layout";

function Product(props) {
  const { pageContext } = props;

  return (
    <Layout sideMenuCollections={pageContext.sideMenuCollections}>
      <ProductPage product={pageContext.product} />
    </Layout>
  );
}

export default Product;
