import React, { useState } from "react";
import {
  Paper,
  Grid,
  Button,
  Box,
  Typography,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import styles from "../../css/main.module.scss";
import "@wordpress/block-library/build-style/style.css";
import LazyLoad from "react-lazyload";
import styles2 from "./PhoneInfo.module.scss";
import { sortByOrder } from "../../config/app";

const PhoneInfo = (props) => {
  const { product } = props;
  const attributes = product.parsedAttributes;
  let filteredAttributes = [];

  Object.keys(attributes).map((section, i) => {
    let hasValue = false;
    Object.values(attributes[section]).forEach((k, i) => {
      if (k.value && k.value.length > 0) {
        hasValue = true;
      }
    });

    if (hasValue) {
      filteredAttributes[section] = attributes[section];
    }

    return null;
  });

  delete filteredAttributes["Key Features"];
  const [expandDesc, setExpandDesc] = useState(false);
  const [expandAttr, setExpandAttr] = useState(true);

  return (
    <React.Fragment>
      <Paper
        elevation={0}
        variant="outlined"
        square
        style={{ marginTop: "2px" }}
      >
        <Grid container direction="column" alignItems="center" justify="center">
          <Grid
            item
            lg={8}
            xs={12}
            container
            direction="column"
            justify="center"
            className={styles2.specsBox}
          >
            <Grid container item xs={12} justify="center">
              <Typography variant="h4">{product.title} Specs</Typography>
            </Grid>
            <Grid item xs={12}>
              <Collapse collapsedHeight="145px" in={expandAttr} timeout="auto">
                <TableContainer component="div">
                  <Table size="small" aria-label="a dense table">
                    <TableHead></TableHead>
                    <TableBody>
                      {Object.keys(filteredAttributes).map((section, i) => {
                        return (
                          <React.Fragment key={i}>
                            <TableRow>
                              <TableCell
                                colSpan={2}
                                className={styles2.titleHeading}
                              >
                                <Box>
                                  <Grid
                                    container
                                    direction="row"
                                    spacing={1}
                                    justify="flex-start"
                                    alignItems="flex-start"
                                  >
                                    <Grid item>
                                      <Box>
                                        <Typography variant="h6">
                                          {section}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </TableCell>
                            </TableRow>

                            {sortByOrder(
                              Object.values(filteredAttributes[section])
                            )
                              .filter((k) => k.value && k.value.length > 0)
                              .map((k, i) => (
                                <TableRow key={i}>
                                  <TableCell component="th" scope="row">
                                    {k.name}
                                  </TableCell>
                                  <TableCell
                                    dangerouslySetInnerHTML={{
                                      __html: k.value,
                                    }}
                                  ></TableCell>
                                </TableRow>
                              ))}
                          </React.Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Collapse>
            </Grid>
          </Grid>
          <Grid item xs={12} container direction="row" justify="center">
            <Grid>
              <Box style={{ margin: "15px" }}></Box>
              <Button
                size="small"
                onClick={() => setExpandAttr(!expandAttr)}
                variant="outlined"
                style={{
                  marginBottom: "20px",
                }}
              >
                {expandAttr === true ? "Hide" : "Show All"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {/* END of SPECS */}
      {/* START OF PHONE ARTICLE */}
      <Paper
        elevation={0}
        variant="outlined"
        square
        className={[styles.mainPaper, styles2.articlepaper].join(" ")}
      >
        <Grid container direction="column" alignItems="center" justify="center">
          <Grid item xs={12}>
            <Typography variant="h4">About {product.title}</Typography>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Collapse collapsedHeight="145px" in={expandDesc} timeout="auto">
              <LazyLoad height={300} once>
                <Typography
                  variant="body2"
                  paragraph
                  align="justify"
                  dangerouslySetInnerHTML={{ __html: product.description }}
                ></Typography>
              </LazyLoad>
            </Collapse>
          </Grid>

          <Grid container item direction="row" justify="center">
            <Grid>
              <Button
                size="small"
                onClick={() => setExpandDesc(!expandDesc)}
                variant="outlined"
                style={{ marginTop: "15px" }}
              >
                {expandDesc === true ? "Hide" : "Read More"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default PhoneInfo;
